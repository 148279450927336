<template>
  <div class="ProjectsTable">
    <div class="ProjectsTable__toolbar">
      <AppTextField
        v-model="quickSearch"
        dense
        outlined
        hide-details
        margins-with-hidden-details="mb-0"
        :placeholder="$t('project.SearchByM')"
        append-icon="mdi-magnify"
        class="ProjectsTable__quick-search"
      />
    </div>

    <div class="ProjectsTable__headers">
      <div
        v-for="header in headers"
        :key="header.value"
        v-ripple="header.sortable === undefined || !!header.sortable ? { class: 'app-ripple' } : false"
        class="ProjectsTable__header"
        :class="{ 'ProjectsTable__header--clickable': header.sortable === undefined || !!header.sortable }"
        :tabindex="header.sortable === undefined || !!header.sortable ? '0' : '-1'"
        :role="header.sortable === undefined || !!header.sortable ? 'button' : undefined"
        :style="header.style"
        @click="onHeaderClick(header)"
        @keydown.enter="onHeaderClick(header)"
        @keydown.space="onHeaderClick(header)"
      >
        <div class="d-flex align-center text-truncate">
          <div
            class="flex-shrink-1 text-truncate"
            v-text="header.text ? header.text : ''"
          />
          <div
            v-if="header.sortable === undefined || !!header.sortable"
            class="ProjectsTable__sort-icon"
            :class="{
              'ProjectsTable__sort-icon--active': sortProp === header.value,
              'ProjectsTable__sort-icon--desc': sortProp === header.value && sortDirection === 'desc',
            }"
          >
            <v-icon
              size="14"
              v-text="'$arrow-down'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ProjectsTable__body">
      <ProjectsTableRow
        v-for="item in items"
        :key="item.id"
        class="ProjectsTable__row"
        :project="item.project"
        :group="item.group"
        :headers="headers"
        :sort-prop="sortProp"
        :sort-direction="sortDirection"
        :expanded-groups="expandedGroups"
        :search-tokens="searchTokens"
        @set-group-expanded="setGroupExpanded($event.groupId, $event.expanded)"
      />
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'

import { removeInPlace } from '../helpers'

import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

import ProjectsTableRow from './ProjectsTableRow'

export default {
  name: 'ProjectsTable',

  components: {
    ProjectsTableRow,
  },

  props: {
    rootGroupId: { type: String, default: null },
  },

  data: () => ({
    sortProp: 'name',
    sortDirection: 'asc',
    quickSearch: '',
    expandedGroups: [],
  }),

  storage: {
    keys: ['sortProp', 'sortDirection', 'expandedGroups'],
    namespace: 'components/ProjectsTable',
  },

  computed: {
    rootGroup() {
      const { rootGroupId } = this
      const q = rootGroupId
        ? ProjectGroup.query().where('id', rootGroupId)
        : ProjectGroup.getRootGroupQ()
      return q.with('projects').with('children').first()
    },

    headers() {
      return [
        {
          text: this.$t('project.TableName'),
          value: 'name',
          style: {
            // 3 to match number of other flexible columns (start + end + type)
            flex: '3',
            minWidth: '200px',
          },
        },
        {
          text: this.$t('project.TableStart'),
          value: 'startDate',
          style: {
            flex: '1',
          },
        },
        {
          text: this.$t('project.TableEnd'),
          value: 'completionDate',
          style: {
            flex: '1',
          },
        },
        {
          text: this.$t('project.TableType'),
          value: 'projectType',
          style: {
            flex: '1',
          },
        },
        {
          text: this.$t('project.TableTeam'),
          value: '_team',
          style: {
            flex: '0 0 200px',
          },
          sortable: false,
        },

        // Actions column
        {
          value: '_actions',
          style: {
            flex: `0 0 ${56 * 3}px`,
          },
          sortable: false,
        },
      ]
    },

    searchTokens() {
      const { quickSearch } = this
      return quickSearch
        .split(/\s+?/g)
        .map(part => part.trim().toLowerCase())
        .filter(Boolean)
    },

    items() {
      const { rootGroup, sortProp, sortDirection, searchTokens } = this
      if (!rootGroup) return null

      return R.pipe(
        // 1. get children (already sorted)
        rootGroup =>
          rootGroup.getSortedDescendants(sortProp, sortDirection),

        // 2. filter when necessary
        R.when(
          () => !!searchTokens.length,
          R.filter(projectOrGroup =>
            searchTokens.every(q => projectOrGroup.searchIndex.includes(q)) || (
              projectOrGroup instanceof ProjectGroup &&
                projectOrGroup.getAllDescendants().some(subChild =>
                  searchTokens.every(q =>
                    subChild.searchIndex.includes(q)))
            )),
        ),

        // 3. transform for table rows iteration
        R.map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
          }
        }),
      )(rootGroup)
    },
  },

  methods: {
    onHeaderClick({ sortable, value }) {
      if (sortable === undefined || !!sortable) {
        if (this.sortProp === value) {
          this.sortDirection =
            this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortProp = value
          this.sortDirection = 'asc'
        }
      }
    },

    setGroupExpanded(groupId, isExpanded) {
      if (!isExpanded) {
        removeInPlace(this.expandedGroups, groupId)
      } else if (!this.expandedGroups.includes(groupId)) {
        this.expandedGroups.push(groupId)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.ProjectsTable
  $self: &

  &__toolbar
    display: flex
    align-items: center

  &__quick-search
    flex: 1

  &__headers
    display: flex
    align-items: center
    height: 48px
    margin-top: 24px
    background: white
    z-index: 2
    position: sticky
    top: 56px

    @media #{map-get($display-breakpoints, 'md-and-up')}
      top: 64px

  &__header
    height: 48px
    line-height: 48px
    white-space: nowrap
    display: flex
    align-items: center
    overflow: hidden
    color: #A09EB9

    > *:first-child
      padding: 0 16px

    &:first-child > *:first-child
      padding-left: 30px + 24px

    &--clickable
      cursor: pointer
      user-select: none

    &:hover, &:active, &:focus, &:focus-within
      #{$self}__sort-icon
        opacity: 1

  &__sort-icon
    flex-shrink: 0
    display: flex
    align-items: center
    justify-content: center
    margin-left: 10px
    transition: all 200ms ease
    opacity: 0
    pointer-events: none

    &--active
      opacity: 1

    &--desc
      transform: rotate(-180deg)

  &__row
    margin-top: 8px
</style>
