<template>
  <ProjectsTableRowProject
    v-if="project != null"
    :project="project"
    class="ProjectsTableRow"
    v-bind="$attrs"
    v-on="$listeners"
  />
  <ProjectsTableRowGroup
    v-else-if="group != null"
    :group="group"
    :search-tokens="searchTokens"
    class="ProjectsTableRow"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ProjectsTableRowGroup from './ProjectsTableRowGroup'
import ProjectsTableRowProject from './ProjectsTableRowProject'

export default {
  name: 'ProjectsTableRow',

  components: {
    ProjectsTableRowGroup,
    ProjectsTableRowProject,
  },

  inheritAttrs: false,

  props: {
    project: { type: Object, default: null },
    group: { type: Object, default: null },
    searchTokens: { type: Array, default: () => [] },
  },
}
</script>

<style lang="sass" scoped>
// @import '../scss/variables'
//
// .ProjectsTableRow
</style>
