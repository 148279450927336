<template>
  <div
    class="EmptyState"
    :class="{
      'EmptyState--secondary': secondary,
    }"
  >
    <div class="EmptyState__content">
      <v-img
        class="EmptyState__image"
        :src="imgSrc"
        :alt="imgAlt"
        :width="imgWidth"
        :height="imgHeight"
      />
      <h3
        class="EmptyState__title"
        v-text="title"
      />

      <div
        v-if="$scopedSlots.actions"
        class="EmptyState__actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n'
export default {
  name: 'EmptyState',

  props: {
    imgSrc: { type: String, default: require('../assets/images/empty-abstract.svg') },
    imgAlt: { type: String, default: i18n.t('layout.EmptyView') },
    imgWidth: { type: Number, default: 380 },
    imgHeight: { type: Number, default: 280 },
    title: { type: String, default: i18n.t('layout.NothingYetM') },
    secondary: { type: Boolean, default: false },
  },
}
</script>

<style lang="sass" scoped>
.EmptyState
  width: 100%
  // If displayed inside a flex container: try to occupy all the place possible
  flex: 1
  align-self: stretch
  justify-self: stretch

  transition: all 300ms ease

  display: flex
  align-items: center
  justify-content: center

  &--secondary
    padding-bottom: 100px

  &__image
    display: block
    margin: 0 auto 17px
  &--secondary &__image
    margin-bottom: 32px

  &__title
    margin: 0 auto
    text-align: center
    max-width: 353px

    font-style: normal
    font-weight: 500
    font-size: 24px
    line-height: 31px
    letter-spacing: 0.009375em
    color: #9898B5 // primary-var/kimberly--lighten-3

  &--secondary &__title
    font-weight: 400
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em
    color: #A09EB9 // Text/Secondary

  &__actions
    margin-top: 17px
    display: flex
    align-items: center
    justify-content: center
</style>
