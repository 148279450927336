<template>
  <div class="AppBarControls">
    <div class="AppBarControls__action AppBarControls__action--icon">
      <v-menu
        bottom
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            :ripple="{ class: 'app-ripple' }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-text="'mdi-information-outline'" />
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            :href="`${PROTOCOL}//help.${source}`"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('layout.Help') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            @click="loadAboutDialog = aboutDialog = true"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('layout.About') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="AppBarControls__action AppBarControls__action--icon">
      <v-badge
        overlap
        :color="hasUnreadNotifications ? 'warning' : 'transparent'"
        dot
        offset-x="12"
        offset-y="12"
      >
        <v-btn
          icon
          :ripple="{ class: 'app-ripple' }"
          @click="toggleSettingsDrawer"
        >
          <v-icon v-text="'mdi-bell-ring-outline'" />
        </v-btn>
      </v-badge>
    </div>

    <div class="AppBarControls__action AppBarControls__action">
      <v-menu
        bottom
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="px-3"
            :ripple="{ class: 'app-ripple' }"
            text
            plain
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-text="'mdi-account-outline'" />
            <span
              class="ml-1"
              v-text="userDisplayName"
            />
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            @click="loadUserDialog = userDialog = true"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('layout.Profile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            @click="logOut"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('layout.LogOut') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <AboutModal
      v-if="loadAboutDialog && aboutDialog && currentUser != null && license != null"
      v-model="aboutDialog"
      :license="license"
      :product-information="productInformation"
      :install-id="installId"
      :source="source"
      :plan="plan"
      platform-name="frigate"
    />

    <UserDialog
      v-if="loadUserDialog && currentUser != null"
      v-model="userDialog"
      :user-id="currentUser.id"
      :password-change-required="changePassword"
      profile
    />
  </div>
</template>

<script>
import { LICENSE_PLAN } from '../constants'
import { pushRoute } from '../helpers'

export default {
  name: 'AppBarControls',

  components: {
    AboutModal: () => import(
      /* webpackChunkName: "about-modal" */
      '@hexway/shared-front/src/components/AboutModal'),
    UserDialog: () => import(
      /* webpackChunkName: "user-dialog" */
      './UserDialog'),
  },

  data() {
    return {
      PROTOCOL: location.protocol,

      loadAboutDialog: false,
      aboutDialog: false,

      loadUserDialog: false,
      userDialog: false,
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
    userDisplayName() {
      const { currentUser: user } = this
      if (!user) return null

      const { firstName, lastName } = user
      return [firstName, lastName]
        .map(name => (name || '').trim())
        .filter(Boolean)
        .join(' ') || user.userLogin
    },

    productInformation() { return this.$store.getters['ext/GET']?.product },
    source() { return this.productInformation?.source || 'hexway.io' },
    license() { return this.$store.state.license.current },
    installId() { return this.$store.getters['ext/GET']?.installation },

    plan() {
      const { license } = this
      return license && LICENSE_PLAN[license.licenseType]
    },

    hasUnreadNotifications() {
      return this.$store.getters['notification/anyUnread']
    },

    settingsDrawer: {
      get() { return this.$store.state.appDrawer.settingsDrawer },
      set(settingsDrawer) {
        this.$store.commit('appDrawer/setSettingsDrawer', settingsDrawer)
      },
    },

    changePassword() {
      return this.$route.query.changePassword === 'true'
    },
  },

  watch: {
    '$route.path': {
      handler: 'fetchNotifications',
      immediate: true,
    },

    changePassword: {
      handler(changePassword) {
        if (changePassword) this.loadUserDialog = this.userDialog = true
      },
      immediate: true,
    },
  },

  methods: {
    fetchNotifications() {
      return this.$store.dispatch('notification/getList')
    },

    toggleSettingsDrawer() {
      this.settingsDrawer = !this.settingsDrawer

      // this should resolve task#1725 & current FF behavior
      if ('Notification' in window && Notification.permission === 'default') {
        Notification.requestPermission()
      }
    },

    logOut() {
      const { $store, $router } = this
      $store.dispatch('user/logOut')
        .then(() => {
          localStorage.removeItem('LicenseStatusBar')
          pushRoute($router, { name: 'Auth' })
        })
    },
  },
}
</script>

<style lang="sass">
.AppBarControls
  display: flex
  align-items: center

  &__action
    display: flex
    align-items: center
    height: 24px

    &--icon
      justify-content: center
      width: 24px

    & + &
      margin-left: 12px

    & + &#{&}--icon
      margin-left: 24px
</style>
