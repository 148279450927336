<template>
  <div class="ProjectsTableRowGroup">
    <div
      v-ripple="{ class: 'app-ripple' }"
      tabindex="0"
      class="ProjectsTableRowGroup__item"
      @click="toggleExpanded"
      @keydown.enter="toggleExpanded"
      @keydown.space="toggleExpanded"
      @contextmenu.prevent="dropdownMenu = true"
    >
      <!-- First cell: group name -->
      <div
        class="ProjectsTableRowGroup__cell text-truncate"
        :style="headersLookup.name && headersLookup.name.style"
      >
        <div
          class="text-truncate flex-grow-1"
          :style="{ marginLeft: (32 * levelTruncated) + 'px', width: '100%' }"
        >
          <div class="d-flex align-center text-truncate">
            <v-icon
              style="margin-right: 6px"
              color="#A09EB9"
              v-text="group.getIcon(expanded)"
            />
            <div
              class="ProjectsTableRowGroup__name text-truncate"
              v-text="group.name"
            />
          </div>
          <div class="ProjectsTableRowGroup__description text-truncate">
            {{ group.description }}
          </div>
        </div>
      </div>

      <!-- Second cell: project start date, empty for groups -->
      <div
        class="ProjectsTableRowGroup__cell"
        :style="headersLookup.startDate && headersLookup.startDate.style"
      />
      <!-- Third cell: project end date, empty for groups -->
      <div
        class="ProjectsTableRowGroup__cell"
        :style="headersLookup.completionDate && headersLookup.completionDate.style"
      />

      <!-- Fifth cell: project type, empty for groups -->
      <div
        class="ProjectsTableRowGroup__cell"
        :style="headersLookup.projectType && headersLookup.projectType.style"
      />

      <!-- Fifth cell: Team -->
      <div
        class="ProjectsTableRowGroup__cell"
        :style="headersLookup._team && headersLookup._team.style"
      />

      <!-- Last cell: actions -->
      <div
        class="ProjectsTableRowGroup__cell pa-0 d-flex justify-end"
        :style="headersLookup._actions && headersLookup._actions.style"
        @click.stop.prevent
        @mousedown.stop
        @touchstart.stop
        @keydown.stop
      >
        <div
          v-if="canManageGroups"
          class="ProjectsTableRowGroup__action"
        >
          <v-btn
            icon
            @click="openEditDialog"
          >
            <v-icon
              color="#A09EB9"
              v-text="'$edit'"
            />
          </v-btn>
        </div>

        <v-menu
          v-model="dropdownMenu"
          offset-x
          left
          :nudge-left="8"
          :min-width="136"
        >
          <template #activator="{ on, attrs }">
            <div class="ProjectsTableRowGroup__action">
              <v-btn
                color="#A09EB9"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </div>
          </template>

          <v-list dense>
            <v-list-item
              :ripple="{ class: 'app-ripple' }"
              @click="openEditDialog"
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('project.Info') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="canManageGroups" />

            <v-list-item
              v-if="canManageGroups"
              :ripple="{ class: 'error--text' }"
              @click="deleteGroup"
            >
              <v-list-item-content>
                <v-list-item-title class="error--text">
                  {{ $t('project.Delete') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <v-expand-transition>
      <div
        v-show="expanded"
        class="ProjectsTableRowGroup__children"
      >
        <ProjectsTableRow
          v-for="item in childItems"
          :key="item.id"
          class="ProjectsTableRowGroup__child"
          :project="item.project"
          :group="item.group"
          :level="level + 1"
          :headers="headers"
          :expanded-groups="expandedGroups"
          :sort-prop="sortProp"
          :sort-direction="sortDirection"
          :search-tokens="
            /* if `this.group` matches search - stop further filtering */
            groupMatchesSearch ? [] : searchTokens
          "
          @set-group-expanded="$emit('set-group-expanded', $event)"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import * as R from 'ramda'

import Dialog from '../store/orm/dialog'
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

export default {
  name: 'ProjectsTableRowGroup',

  components: {
    ProjectsTableRow: () => import('./ProjectsTableRow'),
  },

  props: {
    group: { type: Object, required: true },
    level: { type: Number, default: 0 },

    headers: { type: Array, default: () => [] },
    expandedGroups: { type: Array, default: () => [] },

    sortProp: {
      type: String,
      default: 'name',
      validator: (v) =>
        ['name', 'startDate', 'completionDate', 'projectType'].includes(v),
    },
    sortDirection: {
      type: String,
      default: 'asc',
      validator: (v) => ['asc', 'desc'].includes(v),
    },
    searchTokens: { type: Array, default: () => [] },
  },

  data() {
    return {
      dropdownMenu: false,
      expanded: this.expandedGroups.includes(this.group.id),
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },

    headersLookup() { return R.indexBy(R.prop('value'), this.headers) },

    canManageGroups() { return this.currentUser?.isAdmin },

    levelTruncated() { return Math.min(this.level, 4) },

    groupMatchesSearch() {
      const { group, searchTokens } = this
      return !!searchTokens.length && searchTokens.every(q => group.searchIndex.includes(q))
    },

    childItems() {
      const { group, groupMatchesSearch, sortProp, sortDirection, searchTokens } = this

      return R.pipe(
        // 1. get children (already sorted)
        group =>
          group.getSortedDescendants(sortProp, sortDirection),

        // 2. filter when necessary
        R.when(
          // if `this.group` matches search - don't filter further
          () => !!searchTokens.length && !groupMatchesSearch,
          R.filter(projectOrGroup =>
            searchTokens.every(q => projectOrGroup.searchIndex.includes(q)) || (
              projectOrGroup instanceof ProjectGroup &&
              projectOrGroup.getAllDescendants().some(subChild =>
                searchTokens.every(q =>
                  subChild.searchIndex.includes(q)))
            )),
        ),

        // 3. transform for table rows iteration
        R.map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
          }
        }),
      )(group)
    },
  },

  watch: {
    expanded(expanded) {
      this.$emit('set-group-expanded', { groupId: this.group.id, expanded })
    },
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    },

    async deleteGroup() {
      const { $store, group: { id: projectGroupId } } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('project.DeleteGroupQ'),
        subtitle: this.$t('project.ActionImmediateM'),
        consentLabel: this.$t('project.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) ProjectGroup.dispatch('$delete', { projectGroupId })
    },

    openEditDialog() {
      const { group: { id: projectGroupId } } = this
      Dialog.open({
        componentName: 'ProjectGroupDialog',
        props: { projectGroupId },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.ProjectsTableRowGroup
  $self: &

  &__item
    display: flex
    align-items: center
    height: 62px
    border-radius: 4px
    text-decoration: inherit
    color: inherit
    transition: all 150ms ease
    cursor: pointer

    &:hover, &:active, &:focus
      background: #F5F5F9

  &__cell
    display: flex
    align-items: center
    > *:first-child
      padding-left: 24px

  &__name
    font-weight: 500
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.005em

  &__description
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    color: #A09EB9
    padding-left: 30px

    overflow: hidden
    position: relative
    transition: all 150ms ease

    &::before, &::after
      content: ''
      display: block
      position: absolute
      right: 0
      top: 0
      height: 18px
      width: 190px
      transition: all 150ms ease
      pointer-events: none

    &::before
      background: linear-gradient(to right, transparent, white)

    &::after
      background: linear-gradient(to right, transparent, #F5F5F9)
      opacity: 0

  &__item
    &:hover, &:active, &:focus
      #{$self}__description
        &:before
          opacity: 0
        &:after
          opacity: 1

  &__action
    display: flex
    align-items: center
    justify-content: center
    width: 56px
    height: 48px
    opacity: 0

  &__item
    &:hover, &:active, &:focus, &:focus-within
      #{$self}__action
        opacity: 1

  &__child
    margin-top: 4px
</style>
