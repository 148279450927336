<template>
  <div
    class="ProjectTeam"
    v-on="$listeners"
  >
    <v-tooltip
      v-if="owner"
      key="owner"
      bottom
    >
      <template #activator="{ on, attrs }">
        <UserAvatar
          class="ProjectTeam__member ProjectTeam__member--owner"
          :user="owner"
          size="sm"
          owner-star
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span v-text="$t('project.OwnerName', { ownerDisplayName })" />
    </v-tooltip>
    <v-tooltip
      v-for="member in previewMembers"
      :key="member.id"
      bottom
    >
      <template #activator="{ on, attrs }">
        <div class="ProjectTeam__member-wrapper">
          <UserAvatar
            class="ProjectTeam__member ProjectTeam__member--peak"
            :user="member"
            size="sm"
            v-bind="attrs"
            v-on="on"
          />
        </div>
      </template>
      <span v-text="userDisplayName(member)" />
    </v-tooltip>
    <v-tooltip
      v-if="moreCount && moreCount > 0"
      key="more"
      bottom
    >
      <template #activator="{ on, attrs }">
        <div class="ProjectTeam__member-wrapper">
          <v-avatar
            size="24"
            color="#E3E8F6"
            class="ProjectTeam__member ProjectTeam__member--more"
            v-bind="attrs"
            v-on="on"
          >
            {{ moreCount }}
          </v-avatar>
        </div>
      </template>
      <span v-text="moreText" />
    </v-tooltip>
  </div>
</template>
<script>
import UserAvatar from './UserAvatar'

export default {
  name: 'ProjectTeam',

  components: { UserAvatar },

  props: {
    project: { type: Object, required: true },
    limitMembers: {
      type: Number,
      default: 4,
      validator: v => v >= 1,
    },
  },

  computed: {
    owner() { return this.project.owner },
    ownerDisplayName() { return this.owner && this.userDisplayName(this.owner) },

    permissions() {
      const { $store, project } = this
      return $store.getters['permission/forProject'](project.id)?.map(p => p.user) ||
        project.teamUsers.map(userId => $store.getters['user/get'](userId))
    },
    members() {
      const { permissions, owner } = this
      const ownerId = owner && owner.id
      return permissions && permissions.filter(p => p.id !== ownerId)
    },
    previewMembers() {
      const { members, limitMembers } = this
      if (!members) return null
      return members.length <= limitMembers
        ? members
        : members.slice(0, limitMembers - 1)
    },
    moreCount() {
      const { members, limitMembers } = this
      if (!members) return null
      return members.length <= limitMembers
        ? 0
        : members.length - limitMembers + 1
    },
    moreText() {
      return this.moreCount
        ? this.$tc('project.MoreMembersN', this.moreCount)
        : null
    },
  },

  methods: {
    userDisplayName({ firstName, lastName, userLogin }) {
      return [firstName, lastName]
        .map(s => s?.trim?.())
        .filter(Boolean)
        .join(' ') || userLogin
    },
  },
}
</script>

<style lang="sass">
.ProjectTeam
  display: flex
  align-items: center

  &__member-wrapper
    width: 18px

  &__member
    &--owner
      margin-right: 16px
</style>
