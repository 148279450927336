<template>
  <router-link
    v-ripple="{ class: 'app-ripple' }"
    :to="projectRoute || '#'"
    tabindex="0"
    class="ProjectsTableRowProject"
    :class="{
      'ProjectsTableRowProject--has-description': hasDescription,
    }"
    @contextmenu.native.prevent="dropdownMenu = true"
  >
    <!-- First cell: project name -->
    <div
      class="ProjectsTableRowProject__cell text-truncate"
      :style="headersLookup.name && headersLookup.name.style"
    >
      <div
        class="d-flex text-truncate flex-grow-1"
        :style="{ marginLeft: (32 * levelTruncated) + 'px' }"
      >
        <ConnectionStatus
          class="flex-shrink-0"
          style="margin-right: 6px"
          :connection-id="project.hawserID"
          :connection-name="project.connectionName"
          :last-incoming-pong="project.lastIncomingPong"
          :last-outgoing-ping="project.lastOutgoingPing"
          platform-name="frigate"
        />
        <div class="text-truncate flex-grow-1">
          <div class="ProjectsTableRowProject__name-line text-truncate flex-grow-1">
            <span
              class="ProjectsTableRowProject__name text-truncate"
              v-text="project.name"
            />
            <span
              v-if="project.permission === PERM.LIMITED.value"
              class="ProjectsTableRowProject__assignee-caption text-truncate text--secondary ml-2"
              style="font-size: 13px"
              v-text="$t('project.assignee')"
            />
            <span
              v-if="project.permission === PERM.READONLY.value"
              class="ProjectsTableRowProject__assignee-caption text-truncate text--secondary ml-2"
              style="font-size: 13px"
              v-text="'read-only'"
            />
          </div>
          <div
            v-if="hasDescription"
            class="ProjectsTableRowProject__description text-truncate"
          >
            {{ project.description }}
          </div>
        </div>
      </div>
    </div>

    <!-- Second cell: project start date -->
    <div
      class="ProjectsTableRowProject__cell text-truncate"
      :style="headersLookup.startDate && headersLookup.startDate.style"
    >
      <span
        class="text-truncate"
        v-text="fmtDate(project.startDate)"
      />
    </div>
    <!-- Third cell: project end date -->
    <div
      class="ProjectsTableRowProject__cell text-truncate"
      :style="headersLookup.completionDate && headersLookup.completionDate.style"
    >
      <span
        class="text-truncate"
        v-text="fmtDate(project.completionDate)"
      />
    </div>

    <!-- Fifth cell: project type -->
    <div
      class="ProjectsTableRowProject__cell text-truncate"
      :style="headersLookup.projectType && headersLookup.projectType.style"
    >
      <span
        class="text-truncate"
        v-text="project.projectType || '-'"
      />
    </div>

    <!-- Fifth cell: Team -->
    <div
      class="ProjectsTableRowProject__cell"
      :style="headersLookup._team && headersLookup._team.style"
      @click.stop.prevent="openTeamDialog"
      @mousedown.stop
      @touchstart.stop
    >
      <ProjectTeam :project="project" />
    </div>

    <!-- Last cell: actions -->
    <div
      class="ProjectsTableRowProject__cell pa-0 d-flex justify-end"
      :style="headersLookup._actions && headersLookup._actions.style"
      @click.capture.prevent
      @mousedown.stop
      @touchstart.stop
      @keydown.stop
    >
      <div
        v-if="canManageConnections || project.isConnected"
        class="ProjectsTableRowProject__action"
        :class="{
          'ProjectsTableRowProject__action--always-visible': project.isConnected
        }"
      >
        <v-tooltip
          bottom
          nudge-right="8"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="canManageConnections"
              key="button"
              color="#A09EB9"
              icon
              class="ProjectsTableRowProject__hover-btn"
              v-bind="attrs"
              @click="project.isConnected ? disconnectProject() : openConnectDialog()"
              v-on="on"
            >
              <template v-if="project.isConnected">
                <v-icon
                  key="connected-icon-default"
                  class="ProjectsTableRowProject__hide-on-hover"
                  v-text="'mdi-access-point'"
                />
                <v-icon
                  key="connected-icon-hover"
                  class="ProjectsTableRowProject__show-on-hover"
                  v-text="'mdi-access-point-off'"
                />
              </template>
              <v-icon
                v-else
                key="disconnected-icon-any"
                v-text="'mdi-access-point'"
              />
            </v-btn>
            <v-icon
              v-else-if="project.isConnected"
              key="icon"
              color="#A09EB9"
              v-bind="attrs"
              v-on="on"
              v-text="'mdi-access-point'"
            />
          </template>

          <span
            v-if="canManageConnections"
            key="can-manage"
            v-text="project.isConnected ? $t('project.BreakConnection') : $t('project.ConnectToHive')"
          />
          <span
            v-else-if="project.isConnected"
            key="cannot-manage"
            v-text="$t('project.ConnectedToHive')"
          />
        </v-tooltip>
      </div>
      <div
        :style="canEditProject ? {} : { visibility: 'hidden' }"
        class="ProjectsTableRowProject__action"
      >
        <v-btn
          color="#A09EB9"
          icon
          @click="openEditDialog"
        >
          <v-icon v-text="'$edit'" />
        </v-btn>
      </div>
      <div
        :style="canEditProject ? {} : { visibility: 'hidden' }"
        class="ProjectsTableRowProject__action"
      >
        <v-btn
          color="#A09EB9"
          icon
          @click="loadReportDialog = reportDialogModel = true"
        >
          <v-icon v-text="'mdi-file-download-outline'" />
        </v-btn>
      </div>
      <v-menu
        v-model="dropdownMenu"
        offset-x
        left
        :nudge-left="8"
        :min-width="136"
      >
        <template #activator="{ on, attrs }">
          <div class="ProjectsTableRowProject__action">
            <v-btn
              icon
              color="#A09EB9"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-text="'mdi-dots-vertical'" />
            </v-btn>
          </div>
        </template>

        <v-list dense>
          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            :to="{ name: 'ProjectEdit', params: { projectId: project.id }}"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t('project.Info') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :ripple="{ class: 'app-ripple' }"
            @click="openTeamDialog"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t('project.Team') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            v-if="canDeleteProject"
            :ripple="{ class: 'error--text' }"
            @click="deleteProject"
          >
            <v-list-item-content>
              <v-list-item-title class="error--text">
                {{ $t('project.Delete') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="canLeaveProject"
            :ripple="{ class: 'error--text' }"
            @click="leaveProject"
          >
            <v-list-item-content>
              <v-list-item-title class="error--text">
                {{ $t('project.LeaveProject') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <ReportDialog
      v-if="loadReportDialog && project.id != null"
      v-model="reportDialogModel"
      :project-id="project.id"
    />
  </router-link>
</template>

<script>
import * as R from 'ramda'

import { ConnectionStatus } from '@hexway/shared-front'

import { PROJECT_PERMISSION_LEVEL as PERM } from '../constants'
import { fmtDate, pushRoute, replaceRoute } from '../helpers'

import Dialog from '../store/orm/dialog'
import Project from '../store/orm/project'

import ProjectTeam from './ProjectTeam'

export default {
  name: 'ProjectsTableRowProject',

  components: {
    ConnectionStatus,
    ProjectTeam,
    ReportDialog: () => import(
      /* webpackChunkName: "report-dialog" */
      '../components/ReportDialog'),
  },

  props: {
    project: { type: Object, required: true },
    level: { type: Number, default: 0 },
    headers: { type: Array, default: () => [] },
  },

  data: () => ({
    PERM,

    dropdownMenu: false,
    loadReportDialog: false,
    reportDialogModel: false,
  }),

  computed: {
    currentUser() { return this.$store.getters['user/current'] },

    headersLookup() { return R.indexBy(R.prop('value'), this.headers) },

    levelTruncated() { return Math.min(this.level, 4) },

    projectRoute() {
      return {
        name: 'ProjectDashboard',
        params: { projectId: this.project.id },
      }
    },

    hasDescription() {
      return !!this.project.description?.trim?.()
    },

    canDeleteProject() {
      const { currentUser, project } = this
      if (!project) return null
      return currentUser?.isAdmin || project.permission === PERM.OWNER.value
    },

    canEditProject() {
      const { currentUser, project } = this
      if (!project || !currentUser) return null
      // return currentUser.isAdmin ||
      //   [PERM.OWNER, PERM.EDITOR].map(p => p.value).includes(project.permission)
      return currentUser.isAdmin || project.permission === PERM.OWNER.value
    },

    canLeaveProject() {
      const { permission } = this.project
      // root has `null` permission if he's not a member
      return permission != null && permission !== PERM.OWNER.value
    },

    canManageConnections() {
      const { currentUser, project: { permission } } = this
      return currentUser?.isAdmin || permission === PERM.OWNER.value
    },

    ownerDisplayName() {
      // owner absence should be impossible, but unfortunately it is not
      if (!this.project.owner) return null

      const { firstName, lastName, userLogin } = this.project.owner
      return [firstName, lastName]
        .map(s => s?.trim?.())
        .filter(Boolean)
        .join(' ') || userLogin
    },
  },

  methods: {
    fmtDate,
    pushRoute,
    replaceRoute,

    async deleteProject() {
      const { $store, project: { id: projectId } } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('project.DeleteProjectQ'),
        subtitle: this.$t('project.ActionImmediateM'),
        consentLabel: this.$t('project.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) Project.dispatch('$delete', { projectId })
    },

    async leaveProject() {
      const { $store, project: { id: projectId } } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('project.LeaveProjectQ'),
        subtitle: this.$t('project.ProjectWillBeUnaccessibleM'),
        consentLabel: this.$t('project.Leave'),
      })

      if (agreed) {
        await $store.dispatch('permission/leaveProject', { projectId })
      }
    },

    openTeamDialog() {
      const { project: { id: projectId } } = this
      Dialog.open({
        componentName: 'TeamDialog',
        props: { projectId },
      })
    },
    openEditDialog() {
      return this.$router.push({
        name: 'ProjectEdit',
        params: { projectId: this.project.id },
      })
    },
    openConnectDialog() {
      const { project: { id: projectId } } = this
      Dialog.open({
        componentName: 'HawserConnectionDialog',
        props: { projectId },
      })
    },

    async disconnectProject() {
      const { $store, project } = this
      const { id: projectId } = project

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('project.DisconnectProjectQ'),
        subtitle: this.$t('project.SynchronizationDisabledM'),
        consentLabel: this.$t('project.Disconnect'),
      })

      if (agreed) {
        await $store.dispatch('projectConnections/delete', { projectId })
        $store.commit('$snackbar/setMessage', {
          message: `${project.name} disconnected`,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.ProjectsTableRowProject
  $self: &

  display: flex
  align-items: center
  height: 62px
  border-radius: 4px
  text-decoration: inherit
  color: inherit
  transition: all 150ms ease
  cursor: pointer

  &:hover, &:active, &:focus
    background: #F5F5F9

  a
    color: inherit
    text-decoration: none

  &__cell
    display: flex
    align-items: center

    > *:first-child
      padding-left: 16px

    &:first-child > *:first-child
      padding-left: 24px

  &__name-line
    margin-top: 2px
    font-weight: 500
    font-size: 14px
    line-height: 20px

  &--has-description &__name-line
    margin-top: 0

  &__description
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    color: #A09EB9

    overflow: hidden
    position: relative
    transition: all 150ms ease

    &::before, &::after
      content: ''
      display: block
      position: absolute
      right: 0
      top: 0
      height: 18px
      width: 190px
      transition: all 150ms ease
      pointer-events: none

    &::before
      background: linear-gradient(to right, transparent, white)

    &::after
      background: linear-gradient(to right, transparent, #F5F5F9)
      opacity: 0

  &:hover, &:active, &:focus
    #{$self}__description
      &::before
        opacity: 0
      &::after
        opacity: 1

  &__action
    display: flex
    align-items: center
    justify-content: center
    width: 56px
    height: 48px
    opacity: 0
    transition: all 150ms ease

    &--always-visible
      opacity: 1

  &:hover, &:active, &:focus, &:focus-within
    #{$self}__action
      opacity: 1

  &__hover-btn
    #{$self}__show-on-hover
      display: none
    #{$self}__hide-on-hover
      display: unset

    &:hover, &:active, &:focus, &:focus-within
      #{$self}__show-on-hover
        display: unset
      #{$self}__hide-on-hover
        display: none
</style>
