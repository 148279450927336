<template>
  <div
    class="Projects"
    :class="{
      'Projects--empty': groupsLoaded && !projectsOrGroupsExist,
    }"
  >
    <AppBarLayout
      class="ProjectsAppBar"
      v-bind="$attrs"
    >
      <template #header>
        {{ $t('project.Projects') }}
      </template>

      <template #actions>
        <v-btn
          v-if="groupsLoaded && projectsOrGroupsExist && canCreateProjects"
          color="primary"
          depressed
          :to="createProjectRoute"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('project.Project') }}
        </v-btn>
        <v-btn
          v-if="groupsLoaded && projectsOrGroupsExist && isAdmin"
          color="primary"
          class="ml-3"
          outlined
          @click="openCreateGroupDialog"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('project.Group') }}
        </v-btn>
      </template>
    </AppBarLayout>

    <ProjectsTable v-if="groupsLoaded && projectsOrGroupsExist && isLoaded" />
    <EmptyState
      v-else-if="groupsLoaded && !projectsOrGroupsExist"
      :title="$t('project.NoProjectsM')"
      style="height: 80vh"
    >
      <template #actions>
        <v-btn
          class="mt-6"
          width="314"
          color="primary"
          depressed
          :to="createProjectRoute"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('project.Project') }}
        </v-btn>
      </template>
    </EmptyState>
  </div>
</template>

<script>
import { UserPermissionValueSchema as USER_PERM } from '../api'

import Dialog from '../store/orm/dialog'
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

import AppBarLayout from '../layouts/AppBarLayout'

import EmptyState from '../components/EmptyState'
import ProjectsTable from '../components/ProjectsTable'

export default {
  name: 'Projects',

  components: {
    AppBarLayout,
    EmptyState,
    ProjectsTable,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('project.Projects')),
    }
  },

  data() {
    return {
      isLoaded: false,
    }
  },

  computed: {
    isAdmin() { return this.$store.getters['user/current']?.isAdmin },
    canCreateProjects() {
      const perms = this.$store.getters['user/current']?.permissions
      return perms && (
        perms.includes(USER_PERM.PROJECT_CREATE) ||
        perms.includes(USER_PERM.ALL))
    },

    groupsLoaded() {
      return ProjectGroup.query().exists()
    },

    projectsOrGroupsExist() {
      // there's always at least 1 (root) group
      return ProjectGroup.query().count() > 1 || Project.query().count() > 0
    },

    createProjectRoute() {
      return {
        name: 'ProjectCreate',
        params: {},
      }
    },
  },

  async mounted() {
    await this.$store.dispatch('user/getList')
    this.isLoaded = true
  },

  created() {
    this.fetchGroups()
  },

  methods: {
    fetchGroups() {
      ProjectGroup.dispatch('$getTree', { reload: true })
    },

    openCreateGroupDialog() {
      Dialog.open({
        componentName: 'ProjectGroupDialog',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.Projects
  width: 100%
  padding: 24px 20px
  background: white
  min-height: calc(100vh - 64px)

  &--empty
    background: #EDF0FA
</style>
